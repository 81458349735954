import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ";
import About from "../components/About";
import DevelopmentRoadmap from "../components/DevRoadmap";
import Roadmap from "../components/Roadmap";
import TimelineObserver from "react-timeline-animation";
import Team from "../components/Team";

import { Snackbar } from "@material-ui/core";
// import "aos/dist/aos.css";

const Home = (props) => {
  return (
    <main style={{ overflow: "hidden", width: "100%" }}>
      {/* <img src={Sun} className="hero-img" /> */}
      <Hero
        metamask={props.metamask}
        isWalletConnected={props.isWalletConnected}
        mint={props.mint}
        startDate={props.startDate}
        isActive={props.isActive}
        setIsActive={props.setIsActive}
        setCount={props.setCount}
        count={props.count}
        isPaused={props.isPaused}
        isSoldOut={props.isSoldOut}
        isWhitelisted={props.isWhitelisted}
        exceedLimit={props.exceedLimit}
        isPresale={props.isPresale}
        isPublic={props.isPublic}
        alertState={props.alertState}
        setAlertState={props.setAlertState}
        isMinting={props.isMinting}
      />
      <About />
      {/* <TimelineObserver
        initialColor="#e5e5e5"
        fillColor="#5c862b"
        handleObserve={(setObserver) => (
          <Roadmap className="timeline" setObserver={setObserver} />
        )}
      /> */}
      {/* <DevelopmentRoadmap /> */}

      {/* <FAQ /> */}
      <Team />
      <Footer />

      {/* <img src={PickleJuice} /> */}
      <Snackbar
        open={props.alertState.open}
        autoHideDuration={6000}
        onClose={() =>
          props.setAlertState({ ...props.alertState, open: false })
        }
        className="alert"
      >
        <Alert
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          severity={props.alertState.severity}
          className="alert"
        >
          <h3 style={{ fontSize: "15px" }}>{props.alertState.message}</h3>
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Home;
