import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Discord from "../assets/discord.svg";
import Instagram from "../assets/instagram.svg";
import Twitter from "../assets/twitter.png";
import Opensea from "../assets/opensea.svg";
import Bottom from "../assets/bottom.png";

export default function Footer() {
  return (
    <Container className="footer-container">
      <Box className="footer-box">
        <div>
          <a className="logo-text-footer">Pickle Punk</a>
        </div>
        <p>All Rights Reserved 2021</p>
        {/* <p>
          <a
            className="terms-btn"
            onClick={() => window.location.replace("/terms")}
          >
            Terms and conditions
          </a>
        </p> */}
        <p>
          <a
            className="contract-btn"
            onClick={() =>
              window.open(
                "https://etherscan.io/address/0x7c2e0aA2BA79e9b2C4Ce5008CAFFa9478d63480a#code",
                "_blank"
              )
            }
          >
            0x7c2e0aA2BA79e9b2C4Ce5008CAFFa9478d63480a
          </a>
        </p>
        <div className="footer-socials">
          <img
            src={Discord}
            onClick={() =>
              window.open("https://discord.com/invite/GWAUw7YfaG", "_blank")
            }
          />
          <img
            src={Twitter}
            onClick={() =>
              window.open("https://twitter.com/picklepunknft", "_blank")
            }
          />
          <img
            src={Instagram}
            onClick={() =>
              window.open("https://www.instagram.com/picklepunk.io/", "_blank")
            }
          />
          <img
            src={Opensea}
            onClick={() =>
              window.open("https://opensea.io/collection/pickle-punk", "_blank")
            }
          />
        </div>
      </Box>
      <img src={Bottom} className="bottom" />
    </Container>
  );
}
