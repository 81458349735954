import React, { useEffect, useState } from "react";
import { Container, Box } from "@mui/material/";
import Float from "../assets/float.png";
import Float2 from "../assets/float2.png";
import Float3 from "../assets/float3.png";
import Stash from "../assets/stash.png";

const About = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Container className="container" id="about" style={{ marginTop: 0 }}>
      <img
        src={Float}
        className="floating-pickle float1"
        style={{ transform: `translateY(-${offsetY * 0.4}px) rotate(-20deg)` }}
      />
      <img
        src={Float2}
        className="floating-pickle float2"
        style={{ transform: `translateY(-${offsetY * 0.1}px) rotate(75deg)` }}
      />
      <img
        src={Float3}
        className="floating-pickle float3"
        style={{ transform: `translateY(-${offsetY * 0.3}px) rotate(75deg)` }}
      />
      <img
        src={Stash}
        className="floating-pickle float4"
        style={{ transform: `translateY(-${offsetY * 0.6}px) rotate(75deg)` }}
      />

      <div className="about-title">
        <h2>Pickles,</h2>
        <h2>Pickled Cucumbers</h2>
      </div>

      <div>
        <Box data-aos="fade-up" className="welcome-box">
          <div className="welcome-img-container"></div>
          <div className="welcome-content">
            <p>
              Welcome to the Pickle Jar, where Pickles get Punked. Years ago, a
              huge shipment of cucumbers was on its way by sea from India to the
              United States. Unfortunately, while the ship was in the middle of
              the ocean, a huge thunderstorm broke out, and the vessel sank.
              Everyone on board died tragically, and the containers of cucumbers
              made their way to the bottom of the ocean.
            </p>
            <p>
              However, the conditions of the water in which the cucumbers were
              submerged were optimal for pickling, and a crowd of 9,999
              cucumbers were turned into pickles. Nobody knows why, but it seems
              as if the thunderstorm gave the ship and its content magical
              powers, as the pickles morphed into living beings.
            </p>
            <p>
              Some say the 9,999 pickles now live together on an island, but is
              this just another legend?
            </p>
          </div>
        </Box>
      </div>
    </Container>
  );
};

export default About;
