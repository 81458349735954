import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import img from "../assets/joe.png";
import img2 from "../assets/karen.png";
import img3 from "../assets/pilot.png";

export default function FAQ() {
  return (
    <Container className="container" id="team">
      <h2>Team</h2>
      <Grid container>
        <Grid item xs={12} md={4} lg={4} className="team-item-container">
          <img className="team-img" src={img} alt="piclke-punk-Team" />
          <div className="team-desc">
            <h3>@Pickle Joe</h3>
            <p>Pickle Programmer</p>
          </div>
        </Grid>

        <Grid item xs={12} md={4} lg={4} className="team-item-container">
          <img className="team-img" src={img2} alt="piclke-punk-Team" />
          <div className="team-desc">
            <h3>@Pickle Karen</h3>
            <p>Art Pickle</p>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="team-item-container">
          <img className="team-img" src={img3} alt="piclke-punk-Team" />
          <div className="team-desc">
            <h3>@Pickle Pilot</h3>
            <p>Marketing Pickle</p>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
