import React, { useState, useEffect } from "react";
import background from "../assets/bg-2.png";
import Cloud1 from "../assets/cloud1.png";
import Cloud2 from "../assets/cloud2.png";
import Cloud3 from "../assets/cloud3.png";
import Cloud4 from "../assets/cloud4.png";
import Cloud5 from "../assets/cloud5.png";
import Cloud6 from "../assets/cloud6.png";
import Cloud7 from "../assets/cloud7.png";
import Cloud8 from "../assets/cloud8.png";
import PickleJuice from "../assets/pickle-juice.png";
import Island from "../assets/island.png";
import Countdown from "react-countdown";
import Grid from "@mui/material/Grid";
import MintButton from "./MintButton";

const Hero = (props) => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    return (
      <Grid
        container
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
          border: "solid 1px rgba(255,255,255,0.3)",
          padding: ".875rem",
        }}
        className="coutdown-grid"
      >
        <Grid item xs={3} className="countdown-item countdown-border-right">
          <p className="countdown-value">{days}</p>
          <p className="countdown-text">DAYS</p>
        </Grid>
        <Grid item xs={3} className="countdown-item countdown-border-right">
          <p className="countdown-value">{hours}</p>
          <p className="countdown-text">HOURS</p>
        </Grid>
        <Grid item xs={3} className="countdown-item countdown-border-right">
          <p className="countdown-value">{minutes}</p>
          <p className="countdown-text">MINUTES</p>
        </Grid>
        <Grid item xs={3} className="countdown-item">
          <p className="countdown-value">{seconds}</p>
          <p className="countdown-text">SECONDS</p>
        </Grid>
      </Grid>
      // <span>
      //   {days}-{hours}:{minutes}:{seconds}
      // </span>
    );
  };

  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        {/* <img src={background} className="hero-img" /> */}
        {/* <img src={Sun} className="hero-img" /> */}
        {/* <div
          style={{
            position: "relative",
            transform: `translate(-${offsetY * 0.15}px)`,
          }}
        > */}
        {/* <div style={{ position: "relative" }}> */}
        <img src={Cloud1} className="hero-img cloud1 cloud" />
        <img src={Cloud2} className="hero-img cloud2 cloud" />
        <img src={Cloud3} className="hero-img cloud3 cloud" />
        <img src={Cloud4} className="hero-img cloud4 cloud" />
        <img src={Cloud5} className="hero-img cloud5 cloud" />
        <img src={Cloud6} className="hero-img cloud6 cloud" />
        <img src={Cloud7} className="hero-img cloud7 cloud" />
        <img src={Cloud8} className="hero-img cloud8 cloud" />
        {/* </div> */}
        {/* </div> */}

        {/* <h1 style={{ transform: `translate(-50%, -${offsetY * 0.15}px)` }}>
          Pickle Punk
        </h1> */}

        <div className="jar">
          {/* <h1 style={{ transform: `translate(-50%, -${offsetY * 0.15}px)` }}>
            Pickle Punk
          </h1> */}

          <img src={PickleJuice} className="hero-img pickle-juice" />
          <img src={Island} className="hero-img island" />
        </div>

        <div
          className="minter-navbar"
          style={{
            position: "absolute",
            transform: "translateX(-50%)",
            bottom: "35%",
            left: "50%",
            zIndex: 10000,
          }}
        >
          <div
            className="discord-btn"
            onClick={() =>
              window.open("https://opensea.io/collection/pickle-punk", "_blank")
            }
          >
            Buy on opensea
          </div>
        </div>

        <div className="countdown-container">
          {/* <h3 style={{ fontSize: "30px" }}>
            PUBLIC SALE ON DECEMBER 1ST, 9 PM EST
          </h3>
          <Countdown
            date={props.startDate}
            renderer={renderer}
          />
          <MintButton
            metamask={props.metamask}
            isWalletConnected={props.isWalletConnected}
            mint={props.mint}
            startDate={props.startDate}
            isActive={props.isActive}
            setActive={props.setActive}
            setCount={props.setCount}
            count={props.count}
            isPaused={props.isPaused}
            isSoldOut={props.isSoldOut}
            isWhitelisted={props.isWhitelisted}
            exceedLimit={props.exceedLimit}
            isPresale={props.isPresale}
            isPublic={props.isPublic}
            alertState={props.alertState}
            setAlertState={props.setAlertState}
            isMinting={props.isMinting}
          /> */}
        </div>
      </div>

      {/* <Box style={{ margin: "2em 0" }}>
        <div className="hero-minter">
          <Button
            onClick={() =>
              window.open(
                "https://opensea.io/",
                "_blank"
              )
            }
            variant="contained"
            className="mint-button"
          >
            Buy on OpenSea
          </Button>
        </div>
      </Box> */}
      {/* </Container> */}
    </div>
  );
};

export default Hero;
